var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.users,"fields":_vm.fields,"store":"product.purchase_suppliers","resource":"/purchase/suppliers","creatable":"","enterable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.email,"noTranslate":""}})],1)]}},{key:"link",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.link)?_c('TLink',{attrs:{"href":item.link,"content":item.link}}):_c('TMessage',{attrs:{"content":item.link,"noTranslate":""}})],1)]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.address,"noTranslate":""}})],1)]}},{key:"note",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name,"placeholder":"Supplier"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"email-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.email,"placeholder":"Email"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "email", $event)},_vm.filterChange]}})]},proxy:true}])}),_c('AddSupplier',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }